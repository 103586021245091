
// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import InfoIcon from "@mui/icons-material/Info";

// Pages
//import AboutUs from "layouts/pages/landing-pages/about-us";
import ComingSoon from "layouts/pages/landing-pages/coming-soon";
import ContactUs from "layouts/pages/landing-pages/contact-us";
//import Author from "layouts/pages/landing-pages/author";
//import SignIn from "layouts/pages/authentication/sign-in";

// Sections

const routes = [
  {
    name: "solutions",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "automated Inventory Counting and Control",
        description: "Real-time data to track inventory levels",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,
      },
      {
        name: "image Recognition for Quality Control and Damaged Parts",
        description: "Advanced image recognition technology to inspect products for defects and damages",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,
      },
      {
        name: "automated Warehouse Optimization",
        description: "Comprehensive service to optimize warehouse layouts",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,
      },
      {
        name: "Inventory and Warehouse Management Platform",
        description: "Integrates with other modules and leverages AI to automate routine tasks",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,
      },
    ],
  },
  {
    name: "products",
    icon: <Icon>view_day</Icon>,
    collapse: [
          {
            name: "wolverine Invent AI",
            route: "/pages/landing-pages/coming-soon",
            description: "Inventory control system",
            component: <ComingSoon />,
          },
          {
            name: "wolverine Automation",
            route: "/pages/landing-pages/coming-soon",
            description: "Automated Inventory management",
            component: <ComingSoon />,
          },
        ],
  },
  {
    name: "Community",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "getting started",
        description: "All about overview, quick start, and services",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,
  },
      {
        name: "foundation",
        description: "The architecture",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,
  },
      {
        name: "components",
        description: "Explore",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,
  },
      {
        name: "modules",
        description: "Check out how you can integrate our modules",
        route: "/pages/landing-pages/coming-soon",
        component: <ComingSoon />,
  },
    ],
  },
  {
    name: "contact us",
    icon: <InfoIcon />,
    route: "/pages/landing-pages/contact-us",
    component: <ContactUs />,
},
];

export default routes;
